import React, { useState } from "react";
import { logEvent } from "../GoogleAnalytics";

const PlayButton = ({ setPlay }) => {
  const [hovered, setHovered] = useState(false);
  const handleButtonClick = () => {
    // Perform your button click logic
    // ...
    setPlay(true);
    // Log the button click event
    logEvent("Play", "User Clicked Play", "Play Click");
  };
  return (
    <div className="w-full portrait:flex portrait:justify-center fade-in">
      <div
        id="Portrait-Play-Button"
        className="landscape:hidden h-20 w-48 md:h-[7rem] md:w-[15rem] lg:h-[8rem] lg:w-[18rem] xl:h-[12rem] xl:w-[28rem]  text-theme-white-500 
 font-normal relative saturate-150 "
      >
        <div
          className={
            "absolute z-10 top-0 bg-play-background w-full h-full bg-contain bg-no-repeat " +
            (hovered && "grayscale brightness-200 contrast-200 ")
          }
        ></div>
        <div className=" absolute z-20 -mt-1 lg:mt-0 xl:-mt-1 top-0 pl-2 lg:pl-5 2xl:pl-10 text-2xl md:text-3xl lg:text-4xl xl:text-5xl  flex items-center  h-full italic portrait:pl-0 w-full">
          <div
            className={
              "portrait:text-center portrait:w-full font-TrebuchetBoldItalic" +
              (hovered && "text-theme-red-500")
            }
          >
            <span
              className="cursor-pointer"
              onMouseOver={() => setHovered(true)}
              onMouseOut={() => setHovered(false)}
              onClick={() => handleButtonClick()}
            >
              PLAY FILM
            </span>
          </div>
        </div>
      </div>
      <div
        id="Landscape-Play-Button"
        className="portrait:hidden h-8 w-auto md:h-12 lg:h-20 xl:h-20 2xl:h-28 3xl:h-28 4xl:h-[13rem] 2xl:w-auto  portrait:h-24 portrait:text-2xl portrait:w-52  4xl:w-auto text-theme-white-500 
 font-normal relative saturate-150"
      >
        <div
          className={
            "absolute z-10 top-0 bg-play-background w-full h-full bg-contain bg-no-repeat  " +
            (hovered && "grayscale brightness-200 contrast-200 ")
          }
        ></div>

        <div className=" absolute z-20 -mt-1 lg:mt-0 xl:-mt-1 top-0 pl-2 lg:pl-5 2xl:pl-10 sm:text-sm md:text-lg lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl 4xl:text-6xl flex items-center  h-full italic portrait:pl-0 w-full">
          <div
            className={
              "portrait:text-center portrait:w-full " +
              (hovered && "text-theme-red-500")
            }
          >
            <span
              className="cursor-pointer"
              onMouseOver={() => setHovered(true)}
              onMouseOut={() => setHovered(false)}
              onClick={() => handleButtonClick()}
            >
              PLAY FILM
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayButton;
