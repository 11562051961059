// GoogleAnalytics.js
import ReactGA from "react-ga4";

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
