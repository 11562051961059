import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const HeroBody = ({ display }) => {
  return (
    <div className="bg-black bg-opacity-40 rounded-3xl body-box-shadow">
      {display === "Home" && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 2 } }}
            className="hidden sm:block my-2 lg:my-4 3xl:my-8 4xl:my-10"
          >
            <div>
              The life of a directionless young man takes a violent turn in
              rural Florida.
            </div>
          </motion.div>
        </AnimatePresence>
      )}
      {display === "Description" && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 2 } }}
            className=" my-2 lg:my-4 3xl:my-8 4xl:my-10"
          >
            <div>
              Dylan is a directionless young man, living in rural Florida,
              cutoff from the few people around him by his mental illness. When
              his older brother is fed up with his behavior, he sends Dylan away
              to stay with his only other friend. Things take a violent turn
              when Dylan and his buddy go into the woods to practice shooting
              guns.
            </div>
          </motion.div>
        </AnimatePresence>
      )}
      {display === "Credits" && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 2 } }}
            className="my-2 lg:my-4 3xl:my-8 4xl:my-10"
          >
            <div className="font-TrebuchetItalic text-theme-red-500">
              <span className="font-TrebuchetBold text-theme-white-500">
                LONESOME PICTURES
              </span>{" "}
              PRESENTS{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                MADE BY GOD
              </span>{" "}
              STARRING{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                TITUS HEDSTROM JUSTIN CAPAZ DENNIS MARTINELLI
              </span>{" "}
              MUSIC BY{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                JUSTIN GARCIA CARSON COX
              </span>{" "}
              COSTUME DESIGNER{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                DEVON MOORE WEYMOUTH
              </span>{" "}
              PRODUCTION DESIGNER{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                RUSTON HEAD
              </span>{" "}
              CINEMATOGRAPHER{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                JUSTIN MULLALLY
              </span>{" "}
              WITH SONGS FROM{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                INVADE
              </span>{" "}
              PRODUCERS{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                WYATT WEYMOUTH JAI LOVE
              </span>{" "}
              SCREENWRITERS{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                WYATT WEYMOUTH ANDREW OSBORNE
              </span>{" "}
              DIRECTOR{" "}
              <span className="font-TrebuchetBold text-theme-white-500">
                WYATT WEYMOUTH{" "}
              </span>{" "}
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default HeroBody;
